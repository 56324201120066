// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-about-us-js": () => import("./../../../src/pages/about/about-us.js" /* webpackChunkName: "component---src-pages-about-about-us-js" */),
  "component---src-pages-about-contact-js": () => import("./../../../src/pages/about/contact.js" /* webpackChunkName: "component---src-pages-about-contact-js" */),
  "component---src-pages-about-testimonials-1-js": () => import("./../../../src/pages/about/testimonials-1.js" /* webpackChunkName: "component---src-pages-about-testimonials-1-js" */),
  "component---src-pages-courses-backend-js": () => import("./../../../src/pages/courses/backend.js" /* webpackChunkName: "component---src-pages-courses-backend-js" */),
  "component---src-pages-courses-frontend-js": () => import("./../../../src/pages/courses/frontend.js" /* webpackChunkName: "component---src-pages-courses-frontend-js" */),
  "component---src-pages-courses-python-foundations-js": () => import("./../../../src/pages/courses/python-foundations.js" /* webpackChunkName: "component---src-pages-courses-python-foundations-js" */),
  "component---src-pages-courses-python-telegram-bot-js": () => import("./../../../src/pages/courses/python-telegram-bot.js" /* webpackChunkName: "component---src-pages-courses-python-telegram-bot-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

